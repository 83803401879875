<template>
    <div class="special-heading">
        <h3>Early Deposit</h3>
        <p class="normal-case mt-2">Request the earnest money deposit before the file is ready to be entered into production.</p>
    </div>

    <form id="addEarlyDepositForm" enctype="multipart/form-data" :action="formSubmitUrl" method="post">
        <slot name="csrf" />

        <!-- TODO: Do we need these hidden fields for Early Deposits? -->
        <input type="hidden" name="amount_down" id="amount_down">
        <input type="hidden" name="earnest_money" id="earnest_money">
        <input type="hidden" name="sales_price" id="sales_price">
        <input type="hidden" name="vendor" id="vendor" value="">
        <input type="hidden" name="vendor_order_id" id="vendor_order_id" value="">
        <input type="hidden" name="vendor_transaction_id" id="vendor_transaction_id" value="">

        <div class="form-group flex flex-wrap -mx-2">
            <label class="w-3/12 px-2 form-label">
                <span class="hide-sm">PROPERTY ADDRESS</span>
                <span class="hide-lg">ADDRESS</span>
            </label>
            <div class="w-9/12 px-2" id="locationField">
                <input type="text" class="form-control" name="address" id="address" data-id="address" placeholder="Property address" required>
                <span id="address_error" data-id="address.error" class="form-control-error-description"></span>
            </div>
        </div>

        <SelectCompanyBranch :companies />
        
        <SelectWireDocHtml />

        <div v-if="allowsBalanceDue" class="form-group flex flex-wrap -mx-2" id="input-balance-due-row">
            <label class="w-3/12 px-2 form-label">
                <span class="hide-sm">DEPOSIT AMOUNT</span>
                <span class="hide-lg">DEPOSIT AMT.</span>
            </label>
            <div class="w-9/12 px-2">
                <input type="text" class="form-control" name="balance_due" id="balance_due" data-id="balance_due" placeholder="Deposit amount" ref="balanceDue">
                <span id="balance_due_error" data-id="balance_due.error" class="form-control-error-description"></span>
            </div>
        </div>
         
        <div class="flex flex-wrap -mx-2">
            <div class="w-full px-2">
                <label>EARLY DEPOSIT</label>
                <i class="fas" title="Select wire instructions you need to securely share, and the recipients of the wire instructions." aria-hidden="true" />
            </div>
        </div>

        <div class="product-section" id="sendWireSection">
            <div class="form-group flex flex-wrap -mx-2">
                <label class="w-3/12 px-2 form-label" id="clients-container-label">
                    <span class="hide-sm">CLIENT INFORMATION</span>
                    <span class="hide-lg">CLIENT INFO.</span>
                </label>
                <div class="w-9/12 px-2 flex-col flex-center-vertical">
                    <div class="clients-container">
                        <!-- {{-- filled by JS. See "client-contact-template" --}} -->
                    </div>
                    <div>
                        <small id="addClientButton" class="add_client_btn">
                            <span class="disable-text-select">
                                <i class="fas fa-plus text-green-500" aria-hidden="true" /> Add another client
                            </span>
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-section" id="fileNotificationSection">
            <div class="form-group flex flex-wrap -mx-2">
                <div class="w-6/12 px-2">
                    <label>NOTIFICATION RECIPIENTS</label>
                    <i class="fas" title="Select team members who will get notified when clients complete tasks. 
                    By default this will be the person who is opening the file." aria-hidden="true" />
                </div>
            </div>
            <div class="form-group flex flex-wrap -mx-2">
            <div class="w-4/12 px-2">
                <label class="form-label">SEARCH USERS</label>
            </div>
            <div class="w-8/12 px-2">
                <input id="add-file-search-users" type="text" class="border-neutral-300">
            </div>
            </div>
            <div class="form-group flex flex-wrap -mx-2">
            <div class="w-4/12 px-2">
                <label class="form-label">SELECTED RECIPIENTS</label>
            </div>
            <div class="w-8/12 px-2">
                <div id="add-file-selected-user-list" class="rounded border-neutral-300 border min-h-[220px] p-3">
                <!-- list of selected recipients -->
                </div>
            </div>
            </div>
        </div>

        <br>

        <div class="form-group flex flex-wrap -mx-2 text-center">
            <div class="w-full px-2">
                <div class="custom-control custom-checkbox" id="notify-label">
                    <input class="custom-control-input" id="notify-add-file" name="notify" type="checkbox" value="notify" checked>
                    <label class="custom-control-label" for="notify-add-file">Send notification to client(s)</label>
                </div>
            </div>
        </div>

        <div class="w-full px-2 text-center m-b-20">
            <div class="form-control-error-description" id="errorBlockForHiddenOrMissingElements">
            <!-- This error block is for all server error responses that are not inlined with the input element
            (e.g. if input element is hidden -->
            </div>
        </div>

        <div class="form-group text-center">
            <div class="w-full px-2">
                <button data-was-id="openAddModal" class="add-modal btn btn-primary btn-circle">Send request</button>
            </div>
        </div>

        <slot name="add-client" />

        <!-- MICAHEL TODO: add confirm modal -->
    </form>
</template>

<script setup lang="ts">
import AutoNumeric from 'autonumeric';
import { onMounted, ref } from 'vue';
import SelectCompanyBranch from './SelectCompanyBranch.vue';
import SelectWireDocHtml from './SelectWireDocHtml.vue';

defineProps<{
    allowsBalanceDue: boolean
    companies: { id: string, name: string }[]
    formSubmitUrl: string
}>();

const balanceDue = ref<HTMLElement>();

onMounted(() => {
    new AutoNumeric((balanceDue.value!), { currencySymbol: '$' });
})

const submitForm = () => {}
</script>

<style scoped lang="scss">
@import "resources/sass/add-file-or-pre-file";
</style>
